/** @format */

import { TRoom } from '@/ducks/room/declarations';
import { POI } from '@/types/apiBeds';
import { BooleanParam, NumberParam, StringParam } from 'use-query-params';

export interface ListingStore {
  language?: 'it' | 'en';
  filters: ListingFilters;
  query?: string;
  results: {
    items?: TRoom[];
    totalResults: number;
  };
  pageIndex: number;
  searchByMap: boolean;
  loading: boolean;
  isPoiUrlSet: boolean;
  skipApi: boolean;
}

export interface ListingFilters {
  orderBy: SortingFields;
  monthlyPrice: MonthlyPricing;
  propertyType: PropertyTypeFilters;
  checkInDate: string;
  suitability: SuitabilityFilters;
  facilities: FacilitiesFilters;
  amenities: AmenitiesFilters;
  positions: POI[];
  areas?: MapArea[];
}

export interface Coordinates {
  latitude: number;
  longitude: number;
}

export interface MapArea {
  min_latitude: number;
  max_latitude: number;
  min_longitude: number;
  max_longitude: number;
}

export interface MonthlyPricing {
  min?: number;
  max?: number;
}

// Note: Only one underscore is needed "_", needed to identify the ascending or descending order
export enum SortingFields {
  PRICING_ASC = 'pricing_asc',
  PRICING_DESC = 'pricing_desc',
  AVAILABILITY_ASC = 'availability_asc',
  SIZE_ASC = 'size_asc',
  SIZE_DESC = 'size_desc',
  NULL = 'null',
}

export type SortingFieldsLabels = 'pricing' | 'availability' | 'size';

export interface CountryObject {
  name: string;
  type: string;
  items: CityObject[];
}

export interface CityObject {
  name: string;
  value: string;
}

export interface AmenitiesFilters {
  privateBathroom: boolean;
  balcony: boolean;
  airConditioning: boolean;
  internalSide: boolean;
  clothesDryer: boolean;
  wifi: boolean;
  dishwasher: boolean;
  dryer: boolean;
  washingMachine: boolean;
}

export interface FacilitiesFilters {
  elevator: boolean;
  concierge: boolean;
  bikeParking: boolean;
  topFloor: boolean;
  lowerFloor: boolean;
}

export interface PropertyTypeFilters {
  colivingBuilding: boolean;
  singleRoom?: boolean;
  entireStudio: boolean;
  oneBedroomApartment: boolean;
}

export interface SuitabilityFilters {
  anyGender: boolean; // "tenantConstraintType": "NoRestriction"
  femaleOnly: boolean; // "tenantConstraintType": "WomenOnly"
  twoOccupantsAllowed: boolean; // "twoOccupantsAllowed": true
}

export type TReducerListing = ListingStore;
export type SearchPayload = Partial<ListingStore & ListingFilters>;

export const queryFilters = {
  orderBy: StringParam,
  min: NumberParam,
  max: NumberParam,
  colivingBuilding: BooleanParam,
  singleRoom: BooleanParam,
  entireStudio: BooleanParam,
  checkInDate: StringParam,
  anyGender: BooleanParam,
  femaleOnly: BooleanParam,
  maleOnly: BooleanParam,
  suitableForCouples: BooleanParam,
  elevator: BooleanParam,
  concierge: BooleanParam,
  bikeParking: BooleanParam,
  topFloor: BooleanParam,
  lowerFloor: BooleanParam,
  privateBathroom: BooleanParam,
  balcony: BooleanParam,
  airConditioning: BooleanParam,
  internalSide: BooleanParam,
  clothesDryer: BooleanParam,
  wifi: BooleanParam,
  dishwasher: BooleanParam,
  dryer: BooleanParam,
  washingMachine: BooleanParam,
  positions: StringParam,
  areas: StringParam,
};

export type QueryFilters = typeof queryFilters;
